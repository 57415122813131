import React from 'react';
import { Spinner as Loader } from 'wix-ui-tpa/cssVars';
import { classes, st } from './Spinner.st.css';
import { SpinnerDataHooks } from './const';

export interface SpinnerProps {
  show?: boolean;
  dataHook?: string;
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({
  show,
  dataHook,
  className,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className={st(classes.root, className)}
      data-hook={SpinnerDataHooks.Main}
    >
      <Loader isCentered data-hook={dataHook} />
    </div>
  );
};
