import React from 'react';
import { TimeSlot } from '@wix/ambassador-bookings-availability-v2-time-slot/types';
import { classes } from './WeeklyDatepicker.st.css';
import { WeeklyDatePicker } from 'wix-ui-tpa/cssVars';
import { useDailyAgendaActions } from '../../../components/DailyAgenda/hooks/useDailyAgendaActions';
import {
  getLocalDateTimeEndOfDay,
  getLocalDateTimeStartOfDay,
} from '@wix/bookings-catalog-calendar-viewer-utils/dateAndTime';

export interface WeeklyDatepickerProps {
  availableDates?: TimeSlot[];
  selectedDate?: Date;
}

export const WeeklyDatepicker: React.FC<WeeklyDatepickerProps> = () => {
  const { onWeekChange } = useDailyAgendaActions();

  return (
    <div className={classes.root}>
      <WeeklyDatePicker
        onDisplayedWeekChange={(week) =>
          onWeekChange({
            from: getLocalDateTimeStartOfDay(week.startOfWeek),
            to: getLocalDateTimeEndOfDay(week.endOfWeek),
          })
        }
        removeOuterPadding
        onChange={() => {}}
      />
    </div>
  );
};
