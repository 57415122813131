import React from 'react';
import { Divider } from 'wix-ui-tpa/cssVars';
import { st, classes } from './Widget.st.css';
import { Header } from './Header/Header';
import { Body } from './Body/Body';
import { useWidgetViewModel } from '../hooks/useWidgetViewModel';

export const Widget: React.FC = () => {
  const { isInitDataLoading } = useWidgetViewModel();

  return (
    <div className={st(classes.root, {})} data-hook="DailyAgenda-wrapper">
      <Header />
      <Divider className={classes.divider} />
      {!isInitDataLoading && <Body />}
    </div>
  );
};
