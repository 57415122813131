import React from 'react';
import { classes } from './Header.st.css';
import { Filters } from '../../../../shared/components/Filters/Filters';
import { useWidgetViewModel } from '../../hooks/useWidgetViewModel';
import { WeeklyDatepicker } from '../../../../shared/components/WeeklyDatepicker/WeeklyDatepicker';
import { Title } from '../../../../shared/components/Title/Title';
import { Timezone } from '../../../../shared/components/Timezone/Timezone';
import { HeaderDataHooks } from './const';
import { Spinner } from '../../../../shared/components/Spinner/Spinner';

export const Header: React.FC = () => {
  const {
    isInitDataLoading,
    titleViewModel,
    filtersViewModel,
    timeZoneViewModel,
  } = useWidgetViewModel();

  const renderFilters = () => {
    if (isInitDataLoading) {
      return null;
    }
    return (
      <div
        className={classes.filtersContainer}
        data-hook={HeaderDataHooks.FiltersContainer}
      >
        <div
          className={classes.weeklyDatePicker}
          data-hook={HeaderDataHooks.WeeklyDatepickerContainer}
        >
          <WeeklyDatepicker />
        </div>

        <div
          className={classes.filters}
          data-hook={HeaderDataHooks.FiltersWrapper}
        >
          <Filters filtersViewModel={filtersViewModel} />
          <Timezone timeZoneViewModel={timeZoneViewModel} />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root} data-hook={HeaderDataHooks.Main}>
      <Title titleViewModel={titleViewModel} />
      <Spinner
        className={classes.spinner}
        show={isInitDataLoading}
        dataHook={HeaderDataHooks.HeaderSpinner}
      />
      {renderFilters()}
    </div>
  );
};
